export const seo = {
  url: '404',
  title: {
    en: '404 | Page not found',
    es: '404 | Página no encontrada',
    ro: '404 | Pagina nu a fost găsită',
  },
  desc: {
    en: 'Seems like the page you are looking for does not exist. Go to homepage by clicking the button',
    es: 'Parece que la página que buscas no existe. Ir a la página de inicio haciendo clic en el botón',
    ro: 'Se pare că pagina pe care o căutați nu există. Accesați pagina de pornire făcând clic pe butonul',
  },
  keywords: ['omida', 'logistics', '404'],
}

export const intro = {
  title: {
    en: 'End of the road',
    es: 'Callejón sin salida',
    ro: 'Sfârșitul drumului',
  },
  desc: {
    en: 'Seems like the page you are looking for does not exist. Go to homepage by clicking the button',
    es: 'Parece que la página que buscas no existe. Ir a la página de inicio haciendo clic en el botón',
    ro: 'Se pare că pagina pe care o căutați nu există. Accesați pagina de pornire făcând clic pe butonul',
  },
  button: {
    text: {
      en: 'Turn back!',
      es: '¡Volver!',
      ro: 'Întoarceți-vă!',
    },
    link: '/',
  },
}
